
$primary:   #7BD6DC;
$secondary: #BA5AED;
$success:   #00C45B;
$info:      #476BFF;
$warning:   #F2C200;
$danger:    #F03D0E;
$light:     #FFFFFF;
$dark:      #292929;

$focus-ring-width: 2px;
$focus-ring-opacity: 1;
$focus-ring-blur: 0;
$focus-ring-color: rgba($primary, $focus-ring-opacity);

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";



$theme-colors: (
  "primary":      $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);


@import "node_modules/bootstrap/scss/bootstrap";